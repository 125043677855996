import {
  BrowserRouter as Router,
  Switch,
  Route  
} from "react-router-dom";
import Theme from "./Theme";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import PasswordRecoveryCode from "./PasswordRecoveryCode";
import SetNewPassword from "./SetNewPassword";
import UpdatePassword from "./UpdatePassword";
import NoMatch from "./NoMatch";
import Setup from "./Setup";
import { NotificationState } from "./Notification";

function App() {

  return (
    <NotificationState>
      <Theme>

        <Router >

          <Switch>
            <Route exact path="/forgot">
              <ForgotPassword />
            </Route>
            <Route exact path="/passwordrecovery/:email">
              <PasswordRecoveryCode />
            </Route>
            <Route exact path="/setnewpassword/:email">
              <SetNewPassword />
            </Route>            
            <Route exact path="/updatepassword">
              <UpdatePassword />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/setup/:id">
              <Setup />
            </Route>
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>

        </Router >

      </Theme>
    </NotificationState>



  );
}

export default App;
